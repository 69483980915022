.mb-15 {
    margin-bottom: 15px;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.76);
    z-index: 9999;
    display: none;
    justify-content: center;
    align-items: center;
}

.preloader-content {
    /* Add your preloader content styles here */
}

